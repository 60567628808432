<template>
    <div id="people-locus" class="people-locus">
        <div class="people-main">
            <div class="calendar">
                <v-calendar
                    :attributes="attrs"
                    @dayclick="dayclick"
                    @update:frompage="changeMonth"
                    @update:topage="changeMonth"
                >
                </v-calendar>
            </div>
            <div class="people-list">
                <p class="title">
                    巡查员
                    <span>
                        {{ selectedDate }}
                    </span>
                </p>
                <ul class="scrollbar">
                    <li
                        v-for="(item, index) in peopleList"
                        :key="index"
                        :class="{ current: index === currentIndex }"
                        @click="
                            peopleClick(
                                item.XCY,
                                item.REALNAME,
                                item.PHOTOPATH,
                                index
                            )
                        "
                    >
                        <img
                            :src="
                                item.PHOTOPATH ||
                                require('@image/monitoring_cloud/photo.png')
                            "
                            alt=""
                            class="photo"
                        />
                        <span class="name">{{ item.REALNAME }}</span>
                    </li>
                    <li v-if="peopleList.length === 0">今日无巡查！</li>
                </ul>
            </div>
            <div v-if="patrolDetail.name" class="people-info">
                <img
                    :src="
                        patrolDetail.photoPath ||
                        require('@image/monitoring_cloud/photo.png')
                    "
                    alt=""
                    class="photo"
                />
                <p class="name">{{ patrolDetail.name }}</p>
                <p class="date">{{ patrolDetail.date }}</p>
                <p v-if="patrolDetail.distance" class="distance">
                    巡查路程约 {{ patrolDetail.distance }} km
                </p>
                <p v-if="patrolDetail.time" class="time">
                    巡查时间 {{ patrolDetail.time }} 分钟
                </p>
                <p v-if="patrolDetail.num >= 0" class="num">
                    上传记录 {{ patrolDetail.num }} 条
                </p>
            </div>
        </div>
        <div class="map-main">
            <Mymap class="my-map" @initmap="initMap" :mapData="mapData"></Mymap>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    components: {},
    name: "people-locus",
    data() {
        return {
            mapData: {
                mapTypeBtn: true,
                dxtState: true,
                areaState: true,
            },
            attrs: [
                {
                    dates: [],
                    highlight: {
                        backgroundColor: "red",
                    },
                    contentStyle: {
                        color: "#fff",
                    },
                    dot: {
                        backgroundColor: "#28ccd9",
                        diameter: "10px",
                    },
                },
                {
                    key: "today",
                    dates: new Date(),
                    highlight: {
                        backgroundColor: "#71b471",
                    },
                    contentStyle: {
                        color: "#fff",
                    },
                },
            ],
            selectedDate: "",
            peopleList: [],
            path: null,
            myMap: null,
            randomPath: [],
            nav: null,
            start: null,
            end: null,
            startMaker: null,
            endMaker: null,
            patrolInfo: [],
            patrolDetail: {
                name: "",
                date: "",
                distance: "",
                time: "",
                num: "",
            },
            currentIndex: "",
            routeGroup: null, //轨迹图层组
        };
    },
    computed: {
        ...mapGetters(["system"]),
    },
    mounted() {
        this.routeGroup = window.L.layerGroup().addTo(this.myMap);
        this.selectedDate = this.common.getDate();
        this.getPatrolInfo();
    },
    methods: {
        ...mapActions([
            "getPatrolInfoByMonth",
            "getPatrolDetail",
            "rectifyDeviation",
        ]),
        // 清空图层组
        clearLayersGroup(group) {
            if (this.common.containGroup(group)) {
                group.clearLayers();
            }
        },
        dayclick(dayInfo) {
            let { day, month, year } = dayInfo;
            let selectedMonth = this.common.getMonth(month);
            let selectedDay = this.common.getDay(day);
            let currentDate = `${year}.${selectedMonth}.${selectedDay}`;
            if (this.selectedDate !== currentDate) {
                this.patrolDetail.name = "";
                this.currentIndex = "";
                this.selectedDate = currentDate;
                this.attrs[1].dates = this.selectedDate;
                this.getPatrolPersonList(this.selectedDate);
                if (this.path) {
                    this.myMap.removeLayer(this.path);
                    this.myMap.removeLayer(this.startMaker);
                    this.myMap.removeLayer(this.endMaker);
                }
            }
        },
        changeMonth(monthInfo) {
            let selectedMonth = this.common.getMonth(monthInfo.month);
            let date = `${monthInfo.year}-${selectedMonth}`;
            this.getPatrolInfo(date);
        },
        async peopleClick(xcyId, name, photoPath, index) {
            this.currentIndex = index;
            let result = await this.getPatrolDetail({
                Date: this.selectedDate,
                Xcy: xcyId,
            });
            this.patrolDetail.name = name;
            this.patrolDetail.photoPath = photoPath;
            this.patrolDetail.date = this.selectedDate;
            this.patrolDetail.distance =
                result.XCXX &&
                result.XCXX[0] &&
                (result.XCXX[0].XCLC / 1000).toFixed(2);
            this.patrolDetail.time =
                result.XCXX && result.XCXX[0] && result.XCXX[0].TJSJ;
            this.patrolDetail.num =
                result.XCXX && result.XCXX[0] && result.XCXX[0].SCJL;
            if (this.path) {
                this.myMap.removeLayer(this.path);
                this.myMap.removeLayer(this.startMaker);
                this.myMap.removeLayer(this.endMaker);
            }
            let ysdata = (result.XCLX && JSON.parse(result.XCLX)) || [];
            if (ysdata.length < 2) return;
            // let point_list = [];
            // ysdata.forEach((item, i) => {
            //     point_list.push({
            //         "loc_time": 1526861692 + i * 5,
            //         "latitude": item[0],
            //         "longitude": item[1],
            //         "coord_type_input": "wgs84"
            //     });
            // });

            // // 百度纠偏
            // // let ak = 'YtoPlNphABZijfLx1KBdTiWYP7QQz6Wa';
            // let newPoints = [];
            // let res = await this.rectifyDeviation({
            //     ak: "42b8ececa9cd6fe72ae4cddd77c0da5d",
            //     point_list: JSON.stringify(point_list)
            // });
            // if (res.status === 0 && res.points.length > 0) {
            //     newPoints = res.points;
            //     let lnewPaths = [];
            //     let wgs;
            //     let gcj;
            //     newPoints.forEach(item => {
            //         gcj = this.bd09togcj02(item.longitude, item.latitude);
            //         wgs = this.gcj02towgs84(gcj[0], gcj[1]);
            //         lnewPaths.push([wgs[1], wgs[0]]);
            //     });
            //     this.randomPath = lnewPaths;
            // } else {
            this.randomPath = ysdata;
            // }
            if (this.randomPath.length > 0) {
                this.drawPath(
                    this.routeGroup,
                    this.randomPath,
                    name,
                    photoPath
                );
            }
        },
        // 百度转火星
        bd09togcj02(bd_lon, bd_lat) {
            let x_pi = (3.14159265358979324 * 3000.0) / 180.0;
            let x = bd_lon - 0.0065;
            let y = bd_lat - 0.006;
            let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
            let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
            let gg_lng = z * Math.cos(theta);
            let gg_lat = z * Math.sin(theta);
            return [gg_lng, gg_lat];
        },
        // 火星转wgs84
        gcj02towgs84(lng, lat) {
            let PI = 3.1415926535897932384626;
            let a = 6378245.0;
            let ee = 0.00669342162296594323;
            let dlat = this.transformlat(lng - 105.0, lat - 35.0);
            let dlng = this.transformlng(lng - 105.0, lat - 35.0);
            let radlat = (lat / 180.0) * PI;
            let magic = Math.sin(radlat);
            magic = 1 - ee * magic * magic;
            let sqrtmagic = Math.sqrt(magic);
            dlat =
                (dlat * 180.0) / (((a * (1 - ee)) / (magic * sqrtmagic)) * PI);
            dlng = (dlng * 180.0) / ((a / sqrtmagic) * Math.cos(radlat) * PI);
            let mglat = lat + dlat;
            let mglng = lng + dlng;
            return [lng * 2 - mglng, lat * 2 - mglat];
        },
        transformlat(lng, lat) {
            let PI = 3.1415926535897932384626;
            let ret =
                -100.0 +
                2.0 * lng +
                3.0 * lat +
                0.2 * lat * lat +
                0.1 * lng * lat +
                0.2 * Math.sqrt(Math.abs(lng));
            ret +=
                ((20.0 * Math.sin(6.0 * lng * PI) +
                    20.0 * Math.sin(2.0 * lng * PI)) *
                    2.0) /
                3.0;
            ret +=
                ((20.0 * Math.sin(lat * PI) +
                    40.0 * Math.sin((lat / 3.0) * PI)) *
                    2.0) /
                3.0;
            ret +=
                ((160.0 * Math.sin((lat / 12.0) * PI) +
                    320 * Math.sin((lat * PI) / 30.0)) *
                    2.0) /
                3.0;
            return ret;
        },
        transformlng(lng, lat) {
            let PI = 3.1415926535897932384626;
            let ret =
                300.0 +
                lng +
                2.0 * lat +
                0.1 * lng * lng +
                0.1 * lng * lat +
                0.1 * Math.sqrt(Math.abs(lng));
            ret +=
                ((20.0 * Math.sin(6.0 * lng * PI) +
                    20.0 * Math.sin(2.0 * lng * PI)) *
                    2.0) /
                3.0;
            ret +=
                ((20.0 * Math.sin(lng * PI) +
                    40.0 * Math.sin((lng / 3.0) * PI)) *
                    2.0) /
                3.0;
            ret +=
                ((150.0 * Math.sin((lng / 12.0) * PI) +
                    300.0 * Math.sin((lng / 30.0) * PI)) *
                    2.0) /
                3.0;
            return ret;
        },

        async getPatrolInfo(month) {
            let currentYear = new Date().getFullYear();
            let currentMonth = this.common.getMonth();
            let date = month || `${currentYear}-${currentMonth}`;
            let depmentid = "";
            if (this.system == "system2") {
                depmentid = localStorage.departmentId;
            }
            this.patrolInfo = await this.getPatrolInfoByMonth({
                Date: date,
                depmentid: depmentid,
            });
            this.attrs[0].dates = [];
            this.patrolInfo.forEach((item) => {
                this.attrs[0].dates.push(new Date(item.XCSJ));
                let selectedDate = this.selectedDate;
                if (item.XCSJ === selectedDate.replace(/\./g, "-")) {
                    this.peopleList = item.XcyList;
                }
            });
        },
        getPatrolPersonList(date) {
            this.peopleList = [];
            this.patrolInfo.forEach((item) => {
                if (item.XCSJ === date.replace(/\./g, "-")) {
                    this.peopleList = item.XcyList;
                }
            });
        },
        initMap(map) {
            this.myMap = map;
        },
        getRandomPath() {
            let path = [[22.18116, 113.53]];
            for (let i = 1; i < 10; i++) {
                let lat = path[i - 1][0] + Math.random() * 0.001;
                let lon = path[i - 1][1] + Math.random() * 0.001;
                path.push([lat, lon]);
            }
            this.randomPath = path;
        },
        drawPath(group, list, name, photoPath) {
            this.setMapView(list);
            this.clearLayersGroup(group);
            let imgBg = require(`@image/bigScreen/daily_inspect/legend/inspector.png`);
            let html = `<div style="position: absolute;
                                    top: 0;
                                    left: 50%;
                                    text-align:center;
                                    transform: translateX(-50%);">
                            <div
                             style="background: rgb(3, 23, 38);
                                    opacity: 0.8;
                                    border-radius: 5px;
                                    white-space:nowrap;
                                    padding:4px 10px;color:#fff;">
                              ${name}
                            </div>
                    <img src=${imgBg} style="margin-top:4px;"/>
                </div> `;

            if (photoPath) {
                html = `<div style="position:relative;" >
                            <div
                             style="background: rgb(3, 23, 38); 
                                    opacity: 0.8;
                                    border-radius: 5px;
                                    white-space:nowrap;
                                    position: absolute;
                                    top: 0;
                                    left: 50%;
                                    text-align:center;
                                    transform: translateX(-50%);
                                    padding:4px 10px;color:#fff;">
                              ${name}
                            </div>
                    <img src=${imgBg} style="margin-top:30px;"/>
                    <img 
                        src=${photoPath} 
                        fit="cover"
                        style="
                            width:28px;
                            height:28px;
                            border-radius:50%;
                            position: absolute;
                            bottom: 19px;
                            left: 50%;
                            transform: translateX(-50%);">
                    </img>
                </div> `;
            }
            let iconUrl = window.L.divIcon({
                html: html,
                iconSize: [40, 52],
                iconAnchor: [20, 84],
                className: "",
            });

            if (list.length === 1) {
                let lng = list[0][1];
                let lat = list[0][0];
                window.L.marker([lat, lng], {
                    icon: iconUrl,
                }).addTo(group);
                return;
            }
            let ary = [];
            list.map((item) => {
                let obj = {
                    lat: item[0],
                    lng: item[1],
                };
                ary.push(obj);
            });
            var seqGroup = window.L.motion
                .seq([
                    window.L.motion.polyline(
                        ary,
                        {
                            color: "rgb(51, 136, 255)",
                        },
                        {
                            speed: 1,
                            duration: 10000,
                            easing: L.Motion.Ease.linear,
                        },
                        {
                            removeOnEnd: false,
                            icon: iconUrl,
                        }
                    ),
                ])
                .addTo(group);
            setTimeout(() => {
                seqGroup.motionStart();
                // seqGroup.on("click", () => {
                //     this.getPatrolPeopleInfo(item.SJCJFZRID);
                //     this.dialogViewerData.title = item.REALNAME;
                // });
            }, 1000);
            // 这里是之前的运动方式
            // this.start = this.randomPath[0];
            // this.end = this.randomPath[this.randomPath.length - 1];
            // // eslint-disable-next-line
            // this.startMaker = L.marker(this.start, {
            //     icon: this.getIcon("start"),
            // }).addTo(this.myMap);
            // // eslint-disable-next-line
            // this.path = L.polyline(this.randomPath);
            // this.myMap.addLayer(this.path);
            // this.snake();

            // // eslint-disable-next-line
            // this.endMaker = L.marker(this.end, {
            //     icon: this.getIcon("end"),
            // }).addTo(this.myMap);
        },
        snake() {
            this.path.snakeIn();
        },
        setMapView(lonLats) {
            let lonArr = [];
            let latArr = [];
            lonLats.forEach((item) => {
                latArr.push(item[0]);
                lonArr.push(item[1]);
            });
            let minLon = Math.min.apply(null, lonArr);
            let minLat = Math.min.apply(null, latArr);
            let maxLon = Math.max.apply(null, lonArr);
            let maxLat = Math.max.apply(null, latArr);
            this.myMap.fitBounds([
                [minLat, minLon],
                [maxLat, maxLon],
            ]);
        },
        getIcon(type) {
            // eslint-disable-next-line
            let normalIcon = L.icon({
                iconUrl: require(`@image/monitoring_cloud/${type}.png`),
                iconSize: [30, 30],
                iconAnchor: [15, 15],
                className: `${type}Maker`,
            });
            // eslint-disable-next-line
            let locationIcon = L.icon({
                iconUrl: require(`@image/monitoring_cloud/${type}.png`),
                iconSize: [30, 35],
                iconAnchor: [15, 35],
                className: `${type}Maker`,
            });
            return type === "location" ? locationIcon : normalIcon;
        },
    },
};
</script>
<style lang="scss" scoped>
#people-locus {
    position: relative;
    background-color: red;
    .people-main {
        width: 320px;
        position: absolute;
        top: 20px;
        left: 20px;
        bottom: 20px;
        right: 0;
        // 日历
        .calendar {
            height: 275px;
        }
        // 人员列表
        .people-list {
            height: calc(100% - 535px);
            background: #fff;
            text-align: left;
            .title {
                position: relative;
                height: 40px;
                line-height: 40px;
                color: #fff;
                padding-left: 20px;
                background-color: #72b572;
                span {
                    position: absolute;
                    right: 20px;
                }
            }
            ul {
                height: calc(100% - 40px);
                overflow: hidden;
                overflow-y: auto;
                li {
                    height: 60px;
                    line-height: 60px;
                    border-bottom: 1px dashed #72b572;
                    padding: 0 10px;
                    cursor: pointer;
                    .photo {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color: palegreen;
                        margin-right: 10px;
                        vertical-align: middle;
                        object-fit: cover;
                    }
                    .name {
                        display: inline-block;
                        width: calc(100% - 60px);
                        height: 40px;
                        line-height: 40px;
                        color: #2c3e50;
                    }
                }
                .current {
                    background: #eaf4eb;
                }
            }
        }
        // 人员详情
        .people-info {
            height: 260px;
            text-align: center;
            padding: 10px;
            background-color: #eaf4eb;
            .photo {
                display: inline-block;
                width: 85px;
                height: 85px;
                border-radius: 50%;
                background-color: khaki;
                object-fit: cover;
            }
            > p {
                height: 30px;
                line-height: 30px;
                color: #003466;
                font-size: 13px;
                font-weight: 550;
            }
        }
    }
    .map-main {
        width: calc(100% - 380px);
        // background-color: deeppink;
        position: relative;
        position: absolute;
        top: 20px;
        left: 360px;
        bottom: 20px;
        right: 20px;
        .my-map {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
    }
}
</style>
